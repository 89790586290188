import React, { useEffect, useState } from "react";
import "./Profile.css";

import PreviousOrders from "../../Components/PreviousOrders/PreviousOrders";
import {
  Logout,
  GetInfo,
  editProfile,
} from "../../Redux/Login/Register/LoginActions";
import {
  GetBillings,
  GetBillingById,
  EditBilling,
  AddBilling,
} from "../../Redux/Billing/BillingActions";
import {
  GetShippings,
  GetShippingById,
  EditShipping,
  AddShipping
} from "../../Redux/Shipping/ShippingActions";
import { GetOrders } from "../../Redux/Order/OrderActions";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PopUp from "../../Components/PopUp/PopUp";
import BillingShippings from "../../Components/Billing&Shipping/BillingShipping";
function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, loading, error } = useSelector((Store) => Store.userReducer);
  const { billings, billing } = useSelector((Store) => Store.BillingReducer);
  const { shippings, shipping } = useSelector((Store) => Store.ShippingReducer);
  const { orders } = useSelector((Store) => Store.OrderReducer);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [oredersData, setOredersData] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    dispatch(GetInfo());
    dispatch(GetBillings());
    dispatch(GetShippings());
    dispatch(GetOrders());
  }, []);

  useEffect(() => {
    if (orders) {
      setOredersData(orders);
    }
  }, [orders]);

  useEffect(() => {
    if (user !== undefined) {
      setFirstName(user?.first_name);
      setLastName(user?.last_name);
      // setUserName(user?.username);
      setGender(user?.gender);
    }
  }, [user]);

  const logoutuser = () => {
    dispatch(Logout(navigate));
  };
  const EditProfileInfo = (e) => {
    e.preventDefault();
    dispatch(editProfile(firstName, lastName, gender));
    setOpen(false);
  };



  return (
    <div className="Main_profile_div">
      <>
        <div className="Profile_div_header">
          <div className="Profile_div_header_text">MY ACCOUNT</div>
        </div>
        <div className="Profile_header_info_container">
          <div className="Profile_header_info_container_text">MY DETAILS</div>
          <div className="Profile_header_info_container_all">
            <div className="header_texts_cont">
              <div className="header_texts_tit">FIRST NAME</div>
              <div className="header_texts">{user?.first_name} </div>
            </div>
            <div className="header_texts_cont">
              <div className="header_texts_tit">LAST NAME</div>
              <div className="header_texts">{user?.last_name} </div>
            </div>
            <div className="header_texts_cont">
              <div className="header_texts_tit">EMAIL</div>
              <div className="header_texts">{user?.email} </div>
            </div>
          </div>
        </div>
        <div className="header_button_section">
          <button className="button_1" onClick={() => navigate("/change_password")}>RESET PASSWORD</button>
          <button className="button_2" onClick={handleOpen}>
            EDIT INFORMATION
          </button>
        </div>
        {open && (
          <form onSubmit={(e) => EditProfileInfo(e)}
          className="form-edit"
          >
            <div className="popup_profile_edit_main_tite">
              EDIT INFORMATION
            </div>
            <div className="popup_profile_edit_content">
              <label className="popup_profile_edit_labels">First Name</label>
              <input
                required
                value={firstName}
                type="text"
                placeholder="First Name"
                className="popup_profile_edit_input"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
              <label className="popup_profile_edit_labels">Last Name</label>
              <input
                required
                value={lastName}
                type="text"
                placeholder="Last Name"
                className="popup_profile_edit_input"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
              <div className="gender_container">
                <div className="gender_div">
                  <input
                    required
                    onChange={(e) => setGender(e.target.value)}
                    type="radio"
                    id="male"
                    name="gender"
                    value="Male"
                    checked={gender == "Male" ? "checked" : ""}
                  />
                  <label for="male"> Male</label>
                </div>
                <div className="gender_div">
                  <input
                    required
                    onChange={(e) => setGender(e.target.value)}
                    type="radio"
                    id="female"
                    name="gender"
                    value="Female"
                    checked={gender == "Female" ? "checked" : ""}
                  />
                  <label for="male"> Female</label>
                </div>
              </div>
              <button type="submit" className="popup_profile_edit_btn">
                SUBMIT
              </button>
            </div>
          </form>
        )}



      </>
      <div className="underline_profile" />
      <div className="profile_body">
        <div className="Profile_header_info_container_text">MY ORDERS</div>
        <div className="profile_order_container">
          {oredersData.map((order, orderIndex) => {
            const formattedDate = new Date(order?.createdAt).toLocaleDateString(
              undefined,
              {
                year: "numeric",
                month: "long",
                day: "numeric",
              }
            );

            return (
              <div key={orderIndex}>
                <PreviousOrders
                  image1={order?.products[0]?.img_url}
                  image2={order?.products[1]?.img_url}
                  rest={((order?.quantity - 2) > 0) ? (order?.quantity - 2) : (0)}
                  order={order.order_number}
                  date={formattedDate}
                  method={order?.payment_method}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="underline_profile" />
      <div className="Profile_header_info_container_text">MY ADDRESSES</div>
      <BillingShippings></BillingShippings>

      <div className="logout_div">
        Click here to{" "}
        <span className="logout_text" onClick={logoutuser}>
          Logout
        </span>
      </div>

    </div>
  );
}

export default Profile;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  apply: [],
  loading: false,
  error: null,
};

const ApplySlice = createSlice({
  name: "apply",
  initialState,
  reducers: {
    ApplyRequest(state, action) {
      state.loading = true;
      state.apply = [];
      state.error = null;
    },
    ApplySuccess(state, action) {
      state.loading = false;
      state.apply = action.payload;
      state.error = null;
    },
    ApplyFail(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.apply = [];
    },
  },
});

export const ApplyAction = ApplySlice.actions;
export default ApplySlice;

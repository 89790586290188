import "./SendQuery.css";
import React, { useRef, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { bookapp } from "./../../Redux/homepage/HomePageAction";
function SendQuery(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const today = new Date();
  const minDate = new Date(today);
  minDate.setDate(minDate.getDate() + 2);

  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6;
  };
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [comment, setComment] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [disable, setdisable] = useState(true);
  const handleclick = () => {
    dispatch(
      bookapp(firstName, lastName, comment, appointmentDate, phoneNumber)
    );
    setFirstname("");
    setLastname("");
    setComment("");
    setAppointmentDate("");
    setPhoneNumber("");
    props.isclosed();
    //refresh the page
  };
  //if any is not valid setdisable true
  useEffect(() => {
    if (
      firstName === "" ||
      lastName === "" ||
      comment === "" ||
      appointmentDate === "" ||
      phoneNumber === ""
    ) {
      setdisable(true);
    } else {
      setdisable(false);
    }
  }, [firstName, lastName, comment, appointmentDate, phoneNumber]);

  return (
    <>
      <div className="click_and_collect_main_container">
        <div className="query_main_tite">Book An Appointment</div>
        <div className="click_and_collect_content">
          <label className="click_and_collect_labels">First Name</label>
          <input
            type="text"
            placeholder="First Name"
            className="click_and_collect_input"
            onChange={(e) => {
              setFirstname(e.target.value);
            }}
          />
          <label className="click_and_collect_labels">Last Name</label>
          <input
            type="text"
            placeholder="Last Name"
            className="click_and_collect_input"
            onChange={(e) => {
              setLastname(e.target.value);
            }}
          />

          <label className="click_and_collect_labels">Phone Number</label>
          <input
            type="text"
            placeholder="Phone Number"
            className="click_and_collect_select"
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          />
        </div>
        <label className="click_and_collect_labels">Item</label>
        <input
          type="text"
          placeholder="Item"
          className="click_and_collect_select"
          onChange={(e) => {
            setComment(e.target.value);
          }}
        />
        <label className="click_and_collect_labels">Appointment Date</label>
        <input
          type="date"
          placeholder="Select Date"
          className="click_and_collect_input"
          min={minDate.toISOString().split("T")[0]}
          onChange={(e) => {
            const selectedDate = new Date(e.target.value);
            if (isWeekend(selectedDate) || selectedDate < minDate) {
              e.target.value = "";
            } else {
              setAppointmentDate(e.target.value);
            }
          }}
        />
        <div className="notice-pre">
          Our team will contact you in 24 hours to confirm your potential
          meeting request, otherwise we won’t be able to proceed with your
          requirement
        </div>
        <button
          className={
            disable
              ? "click_and_collect_submit_btn1"
              : "click_and_collect_submit_btn"
          }
          onClick={handleclick}
          disabled={disable}
        >
          SUBMIT
        </button>
      </div>
    </>
  );
}

export default SendQuery;

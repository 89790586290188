import axios from "axios";
import { ShippingAction } from "./ShippingReducers";

export const GetShippings = () => async (dispatch) => {
  try {
    dispatch(ShippingAction.ShippingRequest());
    const { data } = await axios.get(
      process.env.REACT_APP_API + "/shipping/get"
    );
    dispatch(ShippingAction.ShippingSuccess(data.data));
  } catch (error) {
    dispatch(ShippingAction.ShippingFail(error));
  }
};


export const GetShippingById = (shipping_id) => async (dispatch) => {
  try {
    dispatch(ShippingAction.ShippingByIdRequest());
    const { data } = await axios.get(
      process.env.REACT_APP_API + "/shipping/" + shipping_id
    );
    dispatch(ShippingAction.ShippingByIdSuccess(data.data));
  } catch (error) {
    dispatch(ShippingAction.ShippingByIdFail(error));
  }
};


export const EditShipping = (shipping_id, street_address, region, city, country) => async (dispatch) => {
  try {
    dispatch(ShippingAction.ShippingRequest());
    const { data } = await axios.patch(
      process.env.REACT_APP_API + "/shipping/" + shipping_id,
      {
        street_address: street_address,
        region: region,
        city: city,
        country: country
      }
    );
    dispatch(ShippingAction.ShippingSuccess(data.data));
  } catch (error) {
    dispatch(ShippingAction.ShippingFail(error));
  }
};

export const AddShipping = (first_name,
  last_name,
  mobile,
  country,
  city,
  street_address,
  building_name,
  zipcode,
  region,) => async (dispatch) => {
    try {
      dispatch(ShippingAction.ShippingRequest());
      const { data } = await axios.post(
        process.env.REACT_APP_API + "/shipping",
        {
          street_address: street_address,
          region: region,
          city: city,
          country: country,
          first_name: first_name,
          last_name: last_name,
          mobile: mobile,
          building_name: building_name,
          zipcode: zipcode,

        }
      );
      dispatch(ShippingAction.ShippingSuccess(data.data));
    } catch (error) {
      dispatch(ShippingAction.ShippingFail(error));
    }
  };
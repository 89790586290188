import axios from "axios";
import { CardAction } from "./CardReducers";

export const addToCard = (productId) => async (dispatch) => {
  try {
    dispatch(CardAction.CardRequest());
    const { data } = await axios.post(
      process.env.REACT_APP_API + "/card/" + productId
    );
    dispatch(CardAction.CardSuccess(data));
  } catch (error) {
    dispatch(CardAction.CardFail(error.response?.data?.message));
  }
};

export const getMyCards = () => async (dispatch) => {
  try {
    dispatch(CardAction.CardsRequest());
    const { data } = await axios.get(
      process.env.REACT_APP_API + "/card/my" 
    );
    
    dispatch(CardAction.CardsSuccess(data));
  } catch (error) {
    dispatch(CardAction.CardsFail(error.response?.data?.message));
  }
};



export const editMyCard = (id , quantity) => async (dispatch) => {
  try {
    dispatch(CardAction.EditCardRequest());
    const { data } = await axios.patch(
      process.env.REACT_APP_API + "/card/"+ id , 
      { 
        product_quantity: quantity
      }
    );
    dispatch(CardAction.EditCardSuccess(data.data));
  } catch (error) {
    dispatch(CardAction.EditCardFail(error.response?.data?.message));
  }
};

export const RemoveProductFromMyCard = (productId) => async (dispatch) => {
  try {
    dispatch(CardAction.DeleteCardRequest());
    const { data } = await axios.delete(
      process.env.REACT_APP_API + "/card/one/"+ productId 
    );
    dispatch(CardAction.DeleteCardSuccess(data.data));
  } catch (error) {
    dispatch(CardAction.DeleteCardFail(error.response?.data?.message));
  }
};

export const deleteCart = () => async (dispatch) => {
try {
  dispatch(CardAction.CardRequest());
  const { data } = await axios.delete(
      process.env.REACT_APP_API + "/card/all"
  );
  dispatch(CardAction.CardDeleteSuccess(data.data));
  window.location.reload()
} catch (error) {
  dispatch(CardAction.CardFail(error));
}
};


import "./BuyPreorder.css";
import React, { useRef, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { preoOrder } from "../../Redux/homepage/HomePageAction";
function BuyPreorder(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [comment, setComment] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [special_request, setspecial_request] = useState("");
  const [email, setEmail] = useState("");

  const [disable, setdisable] = useState(true);
  const handleclick = () => {
    dispatch(
      preoOrder(
        props.id,
        firstName,
        lastName,
        special_request,
        email,
        phoneNumber
      )
    );
    setFirstname("");
    setLastname("");

    setPhoneNumber("");
    setspecial_request("");
    setEmail("");
    props.onClose();
    props.Success();
    //refresh the page
  };
  //if any is not valid setdisable true
  useEffect(() => {
    if (
      firstName === "" ||
      lastName === "" ||
      special_request === "" ||
      email === "" ||
      phoneNumber === ""
    ) {
      setdisable(true);
    } else {
      setdisable(false);
    }
  }, [firstName, lastName, special_request, email, phoneNumber]);

  return (
    <>
      <div className="click_and_collect_main_container">
        <div className="click_and_collect_main_tite">Place Order</div>
        <div className="click_and_collect_content">
          <label className="click_and_collect_labels">First Name</label>
          <input
            type="text"
            placeholder="First Name"
            className="click_and_collect_select"
            onChange={(e) => {
              setFirstname(e.target.value);
            }}
          />
          <label className="click_and_collect_labels">Last Name</label>
          <input
            type="text"
            placeholder="Last Name"
            className="click_and_collect_select"
            onChange={(e) => {
              setLastname(e.target.value);
            }}
          />

          <label className="click_and_collect_labels">Phone Number</label>
          <input
            type="text"
            placeholder="Phone Number"
            className="click_and_collect_select"
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          />
        </div>
        <label className="click_and_collect_labels">Email Address</label>
        <input
          type="text"
          placeholder="Email Address"
          className="click_and_collect_select"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />

        <label className="click_and_collect_labels">Special Request</label>
        <input
          type="text"
          placeholder="Special Request"
          className="click_and_collect_select"
          onChange={(e) => {
            setspecial_request(e.target.value);
          }}
        />

        <button
          className={
        
              "click_and_collect_submit_btn"
          }
          onClick={handleclick}
          disabled={disable}
        >
          SUBMIT
        </button>
      </div>
    </>
  );
}

export default BuyPreorder;

import React, { useState } from 'react';
import "./PasswordResetRequest.css";
import { SendOtp } from "../../Redux/Login/Register/LoginActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function PasswordResetRequest() {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleResetRequest = async (e) => {
    e.preventDefault();
    try {
      dispatch(SendOtp(email , navigate));
    } catch (error) {
      // Handle error
    }
  };

  return (
    <div>
      <form onSubmit={handleResetRequest} className="reset_password_form">
        <input
          type="email"
          placeholder="Enter your email"
          className='email_input_reset_pass'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" className='btn_reset_pass'>Send OTP</button>
      </form>
    </div>
  );
}

export default PasswordResetRequest;

import { React, useState } from "react";
import "./Checkout.css";
import image1 from "../../assets/Images/Cart/IMAGE (15).png";
function Checkout() {
  const [items, setItems] = useState([
    {
      id: 1,
      name: "Product A",
      price: 10.99,
      quantity: 1,
      image: image1,
      description: "Seamaster Diver 300M Co-Axial Master Chronometer 43.5 mm",
    },
    {
      id: 2,
      name: "Product B",
      price: 10.99,
      quantity: 1,
      image: image1,
      description: "Seamaster Diver 300M Co-Axial Master Chronometer 43.5 mm",
    },
    {
      id: 3,
      name: "Product C",
      price: 10.99,
      quantity: 1,
      image: image1,
      description: "Seamaster Diver 300M Co-Axial Master Chronometer 43.5 mm",
    },
  ]);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);

  const handleQuantityChange = (id, newQuantity) => {
    const updatedItems = items.map((item) => {
      if (item.id === id) {
        if (newQuantity <= 0) {
          setShowRemoveConfirmation(true); // set state to show confirmation button
          return { ...item, quantity: 0 }; // set quantity to 0
        } else {
          return { ...item, quantity: newQuantity }; // update quantity
        }
      } else {
        return item;
      }
    });

    setItems(updatedItems);
  };

  const calculateSubtotal = () => {
    return items.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
  };
  const calculateTotal = () => {
    const subtotal = items.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
    return subtotal + 3;
  };
  return (
    <div><div className="my_cart">
      <div className="inside">
        <div className="homecontainer">
          <div className="home"> HOME &nbsp; &nbsp;/ &nbsp; &nbsp;<span className="cartname"> ACCOUNT</span></div>
          <div className="myCart">YOUR ORDER HAS BEEN RECIEVIED!</div>
        </div>




      </div>

    </div>
      <div className="Main_cart_div">

        <div className="chekout_header2">ORDER DETAILS </div>
        <div className="order_details">
          <div className="order_details1">
            <div className="order_cont">
              <div className="order_details1_text">ORDER NUMBER</div>
              <div className="order_details1_text1">99320</div>
            </div>
            <div className="order_cont">
              <div className="order_details1_text"> DATE</div>
              <div className="order_details1_text1">March 2, 2023</div>
            </div>
            <div className="order_cont">
              <div className="order_details1_text">EMAIL</div>
              <div className="order_details1_text1"> me@gmail.com </div>
            </div>
            <div className="order_cont">
              <div className="order_details1_text">PAYMENT METHOD</div>
              <div className="order_details1_text1">Cash on Delivery</div>
            </div>
          </div>
          <div className="chekout_header2">ORDER DETAILS </div>
          <div className="cart">

            <div className="cart_products_allcont">
              <div className="firstcontainer">
                <div className="cart_titles">
                  <div className="cart_title width1">PRODUCT</div>
                  <div className="cart_title width2">PRICE</div>
                  <div className="cart_title width3">QUANTITY</div>
                  <div className="cart_title width2">SUBTOTAL</div>
                </div>
                <div className="cart_products">
                  {items.map((item, index) => (
                    <div key={index} className="cart_product_container">
                      <div className="all_container">
                        <div className="cart_product">
                          <div className="cart_product_image">
                            <img
                              src={item.image}
                              alt={item.name}
                              className="cart_img"
                            />
                          </div>
                          <div className="cart_product_description">
                            {item.description}
                          </div>
                        </div>
                        <div className="cart_price">
                          <div className="cart_price_text">
                            ${item.price.toFixed(2)}
                          </div>
                        </div>
                        <div className="cart_quantity">
                          <div className="quantity_div_Container">
                            <div className="quantity_div1">{item.quantity}</div>
                          </div>
                        </div>
                        <div className="cart_subtotal">
                          <div className="cart_subtotal_text">
                            {" "}
                            ${(item.price * item.quantity).toFixed(2)}
                          </div>
                        </div>
                      </div>
                      <div className="Section4_underline" />
                    </div>
                  ))}
                </div>
              </div>
              <div className="cart_total1">
                <div className="cart_total_textch"> CART TOTAL</div>
                <div className="cart_subtotals_text">
                  <div className="cart_subtotals_text1">Subtotal</div>
                  <div className="cart_subtotals_text2">
                    ${calculateSubtotal().toFixed(2)}
                  </div>
                </div>
                <div className="cart_underline" />
                <div className="cart_subtotals_text">
                  <div className="cart_subtotals_text1">SHIPPING</div>
                  <div className="cart_subtotals_text3">
                    Delivery Charge: $3.00
                  </div>

                </div>
                <div className="cart_underline" />
                <div className="cart_subtotals_text">
                  <div className="cart_subtotals_text1">Total</div>
                  <div className="cart_subtotals_text2">
                    ${calculateTotal().toFixed(2)}
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="chekout_header2">ADDRESS </div>
          <div className="address_cont">
            <div className="address_cont1_text">BILLING ADDRESS</div>

            <div className="address_cont1_text1">
              {" "}
              ABC Mall, Dbayeh Main Road, Dbayeh 1202 2010, Lebanon{" "}
            </div>
          </div>
          <div className="address_cont2">
            <div className="address_cont1_text">SHIPPING ADDRESS</div>

            <div className="address_cont1_text1">
              {" "}
              ABC Mall, Dbayeh Main Road, Dbayeh 1202 2010, Lebanon{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Checkout;

import React from "react";
import WatchesViewCard from "./../../Components/WatchesViewCard/WatchesViewCard";
import image from "./../../assets/Images/Cart/IMAGE.png";
import "./Category.css";
import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getproductbuycategory } from "./../../Redux/Products/ProductsAction";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import { useParams } from "react-router-dom";
import Loader from "./../../Components/Loader/Loader";

function CategoryPreO() {
  const [sortby, setsortby] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [source, setSource] = useState("");

  useEffect(() => {
    dispatch(getproductbuycategory(id));
  }, [id, dispatch]);
  const { Categoryproductpre, loading } = useSelector(
    (state) => state.ProductsReducer
  );
  // Now, 'category' holds the value 'Luxury' from the URL
  const [rec1, setrec1] = useState([]);

  useEffect(() => {
    if (Categoryproductpre) {
      setrec1(Categoryproductpre?.data?.data);
    }
  }, [dispatch]);

  const filters = [
    {
      label: "Sort By Price : low to high",
      value: "price-asc",
    },
    {
      label: "Sort By Price : high to low",
      value: "price-desc",
    },
    {
      label: "Random",
      value: "random",
    },
    {
      label: "Sort by availability",
      value: "quantity_limit",
    },
  ];
  const [activePage, setActivePage] = useState(1);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(0);
  const handlePageChange = (pageNum) => { };
  //function that setrec1 empty
  const handleClear = () => {
    setrec1([]);
  };

  const handleFilterChange = (filter) => { };
  return (
    <div>
      <div className="rout">
        <div
          className="homeroute"
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/");
            handleClear();
          }}
        >
          HOME &nbsp;&nbsp;/
        </div>
        <div
          className="watchroute"
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/preorder");
            handleClear();
          }}
        >
          {" "}
          &nbsp;&nbsp; PRE-O
        </div>
        <div className="productroute">
          {" "}
          &nbsp;&nbsp;/ &nbsp;&nbsp; {Categoryproductpre?.data?.catName}{" "}
        </div>
      </div>
      <div className="titleWatch">
        <p>{Categoryproductpre?.data?.catName} </p>
      </div>
      <div className="fsort">
        <div className="filter-row">
          <div className="filter-item" onClick={() => setsortby(!sortby)}>
            <div className="filter_container_title"> Sort By </div>
            <div className="filter_arrow">
              <IoIosArrowDown
                size={20}
                className={sortby ? "arrow_down" : "arrow_up"}
              />
            </div>
          </div>

          {sortby && (
            <div className="Main_filters_card2">
              {filters.map((filter, index) => (
                <div key={index} className="inputs_container">
                  <input
                    type="checkbox"
                    name={filter.value}
                    id={filter.value}
                    value={filter.value}
                    className="checkbox"
                    onClick={() => handleFilterChange(filter.value)}
                  />
                  <label htmlFor={filter.value} className="label_check">
                    {filter.label}
                  </label>
                </div>
              ))}
            </div>
          )}
          <div className="filter-item">
            <div className="filter_container_title">
              {" "}
              Showing 12- Of Results{" "}
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="product">
          {Categoryproductpre?.data?.data?.map((item, index) => (
            <WatchesViewCard
              key={index}
              image={item?.img_url}
              brand={item?.name}
              name={item?.desc}
              price={item?.price + "$"}
              type="Pre-o"
              link={item?.id}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default CategoryPreO;

import axios from "axios";
import { BrandAction } from "./BrandReducers";

export const GetBrands = () => async (dispatch) => {
  try {
    dispatch(BrandAction.BrandRequest());
    const { data } = await axios.get(
      process.env.REACT_APP_API + "/brand/"
    );
    dispatch(BrandAction.BrandSuccess(data.data));
  } catch (error) {
    dispatch(BrandAction.BrandFail(error));
  }
};

export const GetBrand = (id) => async (dispatch) => {
  try {
    dispatch(BrandAction.OneBrandRequest());
    const { data } = await axios.get(
      process.env.REACT_APP_API + "/brand/getOne/"+id
    );

    
    dispatch(BrandAction.OneBrandSuccess(data));
  } catch (error) {
    dispatch(BrandAction.OneBrandFail(error));
  }
};

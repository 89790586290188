import React, { useState, useEffect } from "react";
import "./BillShip.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import CityDropdown from "./Cities";

import "react-notifications/lib/notifications.css";
import isAuth from "../../Utils/isAuth";
import {
    GetBillings,
    GetBillingById,
    EditBilling,
    AddBilling,
} from "../../Redux/Billing/BillingActions";
import {
    GetShippings,
    GetShippingById,
    EditShipping,
    AddShipping,
} from "../../Redux/Shipping/ShippingActions";
import Loader from "../Loader/Loader";
function BillingShippings({ onBillingChange, onShippingChange }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { billings, billing, loading } = useSelector((Store) => Store.BillingReducer);
    const { shippings, shipping } = useSelector((Store) => Store.ShippingReducer);
    const [openShippingADD, setOpenShippingADD] = useState(false);
    const [openBillingADD, setOpenBillingADD] = useState(false);
    const [billingID, setBillingID] = useState("");
    const [shippingID, setShippingID] = useState("");


    const handleBillingChange = (e) => {
        const selectedBillingID = e.target.value;
        setBillingID(selectedBillingID);
        onBillingChange(selectedBillingID);
    };

    const handleShippingChange = (e) => {
        const selectedShippingID = e.target.value;
        setShippingID(selectedShippingID);
        onShippingChange(selectedShippingID);
    };


    useEffect(() => {
        if (!isAuth(dispatch)) navigate("/sign_in");
    }, [dispatch, navigate]);

    useEffect(() => {
        dispatch(GetBillings());
        dispatch(GetShippings());
    }, []);


    const cities = [
        " as Sultan Yaqub At Tahta",
        "Ablah",
        "Abra",
        "Abu Mizan",
        "Ad Dalhamiyah",
        "Ad Dashuniyah",
        "Ad Dikwanah",
        "Ad Dulb",
        "Adlun",
        "Adma",
        "Adunis",
        "Afqa",
        "Ain Qana",
        "Ain Tanta",
        "Ajaltun",
        "Akkar Al Atiqah",
        "Al Amarah",
        "Al Arz",
        "Al Ashrafiyah",
        "Al Azr",
        "Al Babliyah",
        "Al Baddawi",
        "Al Bahsas",
        "Al Bahsasah",
        "Al Barid",
        "Al Basatin",
        "Al Bashurah",
        "Al Bazuriyah",
        "Al Burj",
        "Al Burjayn",
        "Al Bwar",
        "Al Fawwar",
        "Al Fidar",
        "Al Ghaziyah",
        "Al Hadath",
        "Al Hamra",
        "Al Hazimiyah",
        "Al Hirmil",
        "Al Jiyah",
        "Al Judaydah",
        "Al Kaslik",
        "Al Khallah",
        "Al Khinsharah",
        "Al Knaysah",
        "Al Ksarah",
        "Al Kufur",
        "Al Labwah",
        "Al Laqluq",
        "Al Laylakah",
        "Al Luwayzah",
        "Al Maamiltayn",
        "Al Madfun",
        "Al Majadil",
        "Al Majdal",
        "Al Majidiyah",
        "Al Mansuri",
        "Al Mansuriyah",
        "Al Mashrafah",
        "Al Mina",
        "Al Minyah",
        "Al Miyasah",
        "Al Mudawwar",
        "Al Mudayrij",
        "Al Munsif",
        "Al Murayjah",
        "Al Murayjat",
        "Al Musaytbah",
        "Al Musaytibah",
        "Al Mutaylib",
        "Al Mutayn",
        "Al Muzayriah",
        "Al Qalamun",
        "Al Qanabah",
        "Al Qantari",
        "Al Qasimiyah",
        "Al Qattin",
        "Al Qbayyat",
        "Al Qunaytirah",
        "Al Wardaniyah",
        "Al Warhaniyah",
        "Al Yammunah",
        "Aley",
        "Ali An Nahri",
        "Amioun",
        "Ammiq",
        "Amshit",
        "An Nabah",
        "An Nabi Shit",
        "An Nabi Uthman",
        "An Naqqash",
        "Anjar",
        "Ansar",
        "Antilyas",
        "Ar Rabiyah",
        "Ar Rawshah",
        "Ar Ruays",
        "Ar Rumayl",
        "Ar Rumaylah",
        "Aramun",
        "Arayya",
        "Arnun",
        "As Safra",
        "As Safrah",
        "As Sayfi",
        "As Sultan Yaqub",
        "Awkar",
        "Ayn Ar",
        "Ayn Ata",
        "Ayn Hammana",
        "Ayn Ibil",
        "Ayn Najm",
        "Ayn Saadah",
        "Ayn Zaytun",
        "Aynata",
        "Aynturah",
        "Aytit",
        "Az Zaytuniyah",
        "Az Zuaytirah",
        "Az Zuaytiriyah",
        "Baabda",
        "Baalbeck",
        "Babdat",
        "Balabakk",
        "Balat",
        "Barja",
        "Barr Ilyas",
        "Batroun",
        "Baysur",
        "Bayt Al Kuku",
        "Bayt Ash Shaar",
        "Bayt Lif",
        "Bayt Miri",
        "Bcharre",
        "Bdadun",
        "Beirut",
        "Bent Jbail",
        "Berbara",
        "Bhamdun",
        "Bhamdun Al Mahattah",
        "Bibnin",
        "Bidnayil",
        "Bikfaiya",
        "Bir Hasan",
        "Bnashi",
        "Brital",
        "Brummana",
        "Bsaba",
        "Bsalim",
        "Bshamun",
        "Btighrin",
        "Burj Al Barajinah",
        "Burj Hammud",
        "Burj Rahhal",
        "Byaqut",
        "Bziza",
        "Bzummar",
        "Chartoun",
        "Chekka",
        "Chtaura",
        "Dahr Al Bashiq",
        "Dahr El Ahmar",
        "Dalhun",
        "Darayya",
        "Dawra",
        "Dayr Al Ahmar",
        "Dayr Al Harf",
        "Dayr Qubil",
        "Dbayeh",
        "Deir El Moukhalles",
        "Dfun",
        "Dibal",
        "Diddah",
        "Dik Al Mihdi",
        "Douma",
        "Ed Douair",
        "El Aaqaide",
        "El Arz",
        "El Fourzol",
        "El Mina",
        "Falugha",
        "Fanar",
        "Farayya",
        "Fatqa",
        "Fatri",
        "Fayyadiyah",
        "Frat",
        "Furn Ash Shubbak",
        "Ghadir",
        "Ghassaniyah",
        "Ghazir",
        "Ghidras",
        "Ghusta",
        "Hadath Al Jubbah",
        "Hadshit",
        "Haitoura",
        "Halat",
        "Halba",
        "Hamat",
        "Hammana",
        "Hanin",
        "Haouch El Omara",
        "Harat Hurayk",
        "Harbata",
        "Hariis",
        "Harisa",
        "Hasrun",
        "Hayy Mar Rukuz",
        "Hayyatah",
        "Hermel",
        "Ihmij",
        "Irkay",
        "Irsal",
        "Jadra",
        "Jbail",
        "Jdita",
        "Jezzine",
        "Jisr Al Basha",
        "Jisr Nahr Ibrahim",
        "Jita",
        "Joub Jannine",
        "Jounie",
        "Judaydah",
        "Judaydat Ghazir",
        "Jumhur",
        "Jurat At Turmus",
        "Kafr Hazir",
        "Kafr Hbab",
        "Kafr Shikht",
        "Kafr Silwan",
        "Kafrayya",
        "Kayfun",
        "Kfar Tibnit",
        "Khaldah",
        "Kifrayya",
        "Kubba",
        "Kusba",
        "Lasa",
        "Machmouche",
        "Maghdushah",
        "Majdal Anjar",
        "Majdal Bana",
        "Majdalayya",
        "Majdalun",
        "Majdalyun",
        "Mar Dumit",
        "Mar Elias",
        "Mar Ilyas",
        "Mar Mikhail",
        "Mar Nahra",
        "Mar Yusuf",
        "Marjayoun",
        "Mashgharah",
        "Masna Bidnayil",
        "Mayruba",
        "Mazar",
        "Miyasse",
        "Mizyarah",
        "Nabay",
        "Nahr Al Mawt",
        "Nahr Ibrahim",
        "Naqoura",
        "Qabr Chmoun",
        "Qalhat",
        "Qana",
        "Qannabat Brummana",
        "Qaraaoun",
        "Qartaba",
        "Qattin",
        "Qnabah",
        "Qnat",
        "Qurnat Al Hamra",
        "Qurnayil",
        "Rachaiya",
        "Ramlat Al Bayda",
        "Ras Al Ayn",
        "Ras Al Matn",
        "Ras An Naban",
        "Ras Balabakk",
        "Ras Bayrut",
        "Ras Qusta",
        "Rashin",
        "Rayak",
        "Rayfun",
        "Sabtiyah",
        "Saida",
        "Saidoun",
        "Sarba",
        "Sawfar",
        "Sbuba",
        "Shahtul",
        "Shhim",
        "Siblin",
        "Sour",
        "Tabarja",
        "Talabaya",
        "Tall Az Zatar",
        "Talya",
        "Tanayil",
        "Tarayya",
        "Temnine Et Tehta",
        "Trablous",
        "Tripoli",
        "Turbul",
        "Wadi Shuhrur As Sufl",
        "Wata Al Jawz",
        "Yuhmur",
        "Zahle",
        "Zgharta",
        "Zuq Al Kharab",
    ];

    const handleCityChange = (newCity) => {
        setCity(newCity); // Update the city state with the selected city
    };


    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [city, setCity] = useState("");
    const [street, setStreet] = useState("");
    const [region, setRegion] = useState("");
    const [country, setCountry] = useState("Lebanon");
    const [mobile, setMobile] = useState("");
    const [building_name, setBuild] = useState("");
    const [zipcode, setZip] = useState("");

    const handleToggle1 = () => {
        setOpenBillingADD(!openBillingADD);
        setOpenShippingADD(false);
        setFirstName("")
        setLastName("")
        setCity("")
        setStreet("")
        setRegion("")
        setZip("")
        setMobile("")
        setBuild("")


    };
    const handleToggle2 = () => {
        setOpenShippingADD(!openShippingADD);
        setOpenBillingADD(false);
        setFirstName("")
        setLastName("")
        setCity("")
        setStreet("")
        setRegion("")
        setZip("")
        setMobile("")
        setBuild("")

    };


    const AddBillings = (
        firstName,
        lastName,
        mobile,
        country,
        city,
        street,
        building_name,
        zipcode,
        region) => {
        dispatch(
            AddBilling(
                firstName,
                lastName,
                mobile,
                country,
                city,
                street,
                building_name,
                zipcode,
                region
            )
        ).then(() => dispatch(GetBillings()));
        setOpenBillingADD(false);
    };

    const AddShippings = (
        firstName,
        lastName,
        mobile,
        country,
        city,
        street,
        building_name,
        zipcode,
        region) => {
        dispatch(
            AddShipping(
                firstName,
                lastName,
                mobile,
                country,
                city,
                street,
                building_name,
                zipcode,
                region
            )
        ).then(() => dispatch(GetShippings()));


        setOpenShippingADD(false);
    };

    return (
        <div>
            <NotificationContainer />
            <div className="billingandshipping">
                <div className="popup_profile_edit_main_title">
                    <div className="popup_profile_edit_content">
                        <div className="bill-ship-cont" style={{ flexDirection: billings.length > 0 ? "column" : "row" }}>
                            {loading ? (
                                <Loader />
                            ) : (
                                <div className="bill">
                                    <h1 className="ship-h1">Billings</h1>
                                    {billings.length > 0 &&
                                        billings?.map((billing, index) => (
                                            <div key={index} className="radio-container">
                                                <div className="billing-property">
                                                    <div className="inputradio">
                                                        <input
                                                            type="radio"
                                                            name={"billings"}
                                                            id={"billings_" + billing.id}
                                                            value={billing.id}
                                                            className="checkbox-label"
                                                            onChange={handleBillingChange}
                                                        />

                                                        <div className="name">
                                                            {billing?.first_name + " "}
                                                            {billing?.last_name}
                                                        </div>
                                                    </div>
                                                    <div className="detailed">
                                                        {billing?.country + " "}
                                                        {billing?.city + ", "}
                                                        {billing?.region}
                                                    </div>
                                                </div>
                                                <br />
                                                <div className="line" />
                                            </div>
                                        ))}
                                    <button
                                        type="btn"
                                        className="bill_btn"
                                        onClick={(e) => {
                                            handleToggle1();
                                        }}
                                    >
                                        ADD Billing
                                    </button>{" "}


                                    {openBillingADD && (

                                        <form className="form" onSubmit={() => {
                                            AddBillings(firstName,
                                                lastName,
                                                mobile,
                                                country,
                                                city,
                                                street,
                                                building_name,
                                                zipcode,
                                                region);
                                        }}>
                                            <div className="edit_content">
                                                <div className="name-bill-ship">
                                                    <div className="input-group">
                                                        <label className="edit_labels" htmlFor="firstName">
                                                            First Name
                                                        </label>
                                                        <input

                                                            required
                                                            value={firstName}
                                                            type="text"
                                                            id="firstName"
                                                            placeholder="First Name"
                                                            className="edit_input-name"
                                                            onChange={(e) => {
                                                                setFirstName(e.target.value);
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="input-group">
                                                        <label className="edit_labels" htmlFor="lastName">
                                                            Last Name
                                                        </label>
                                                        <input
                                                            required
                                                            value={lastName}
                                                            type="text"
                                                            id="lastName"
                                                            placeholder="Last Name"
                                                            className="edit_input-name"
                                                            onChange={(e) => {
                                                                setLastName(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>


                                                <div className="country">
                                                    <div className="input-group" >
                                                        <label className="edit_labels" htmlFor="country">Country</label>
                                                        <input
                                                            required
                                                            type="text"
                                                            className="edit_input"
                                                            value={country}

                                                        />
                                                    </div><div className="input-group">
                                                        <label className="edit_labels">Region</label>
                                                        <input
                                                            required
                                                            value={region}
                                                            type="text"
                                                            placeholder="Region"
                                                            className="edit_input"
                                                            onChange={(e) => {
                                                                setRegion(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="input-group">
                                                        <label className="edit_labels">Zip Code</label>
                                                        <input
                                                            required
                                                            value={zipcode}
                                                            type="number"
                                                            placeholder="Zip Code"
                                                            className="edit_input"
                                                            onChange={(e) => {
                                                                setZip(e.target.value);
                                                            }}
                                                        />

                                                    </div>
                                                </div>


                                                <div className="address">
                                                    <div className="input-group">
                                                        <label className="edit_labels">Cities</label>
                                                        <CityDropdown className="city" cities={cities} onChange={handleCityChange} />
                                                    </div>
                                                    <div className="input-group">
                                                        <label className="edit_labels">Street</label>
                                                        <input
                                                            required
                                                            value={street}
                                                            type="text"
                                                            placeholder="Street"
                                                            className="edit_input"
                                                            onChange={(e) => {
                                                                setStreet(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="input-group">
                                                        <label className="edit_labels">
                                                            Building Name
                                                        </label>
                                                        <input
                                                            required
                                                            value={building_name}
                                                            type="text"
                                                            placeholder="Building Name"
                                                            className="edit_input"
                                                            onChange={(e) => {
                                                                setBuild(e.target.value);
                                                            }}
                                                        />

                                                    </div>
                                                </div>

                                                <div className="input-group">
                                                    <label className="edit_labels">Mobile</label>
                                                    <input
                                                        required
                                                        value={mobile}
                                                        type="text"
                                                        placeholder="Mobile"
                                                        className="edit_input_mobile"
                                                        onChange={(e) => {
                                                            setMobile(e.target.value);
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                            <button type="submit" className="bill_ship_btn">
                                                SUBMIT
                                            </button>
                                        </form>

                                    )}
                                </div>
                            )}
                            <div className="ship">
                                <div className="bill">
                                    <h1 className="ship-h1">Shippings</h1>
                                    {shippings.length > 0 &&
                                        shippings?.map((shipping, index) => (
                                            <div key={index} className="radio-container">
                                                <div className="billing-property">
                                                    <div className="inputradio">
                                                        <input
                                                            type="radio"
                                                            name={"shippings"}
                                                            id={"shippings_" + shipping.id}
                                                            value={shipping.id}
                                                            className="checkbox-label"
                                                            onChange={handleShippingChange}
                                                        />
                                                        <br />
                                                        <div className="name">
                                                            {shipping?.first_name + " "}
                                                            {shipping?.last_name + " "}
                                                        </div>
                                                    </div>
                                                    <div className="detailed">
                                                        {shipping?.country + " "}
                                                        <br />
                                                        {shipping?.city + ","}
                                                        <br />
                                                        {shipping?.region}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    <button
                                        type="btn"
                                        className="bill_btn"
                                        onClick={(e) => {
                                            handleToggle2();
                                        }}
                                    >
                                        ADD Shipping
                                    </button>
                                    {openShippingADD && (

                                        <form className="form" onSubmit={() => {
                                            AddShippings(firstName,
                                                lastName,
                                                mobile,
                                                country,
                                                city,
                                                street,
                                                building_name,
                                                zipcode,
                                                region);
                                        }}>
                                            <div className="edit_content">
                                                <div className="name-bill-ship">
                                                    <div className="input-group">
                                                        <label className="edit_labels" htmlFor="firstName">
                                                            First Name
                                                        </label>
                                                        <input

                                                            required
                                                            value={firstName}
                                                            type="text"
                                                            id="firstName"
                                                            placeholder="First Name"
                                                            className="edit_input-name"
                                                            onChange={(e) => {
                                                                setFirstName(e.target.value);
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="input-group">
                                                        <label className="edit_labels" htmlFor="lastName">
                                                            Last Name
                                                        </label>
                                                        <input
                                                            required
                                                            value={lastName}
                                                            type="text"
                                                            id="lastName"
                                                            placeholder="Last Name"
                                                            className="edit_input-name"
                                                            onChange={(e) => {
                                                                setLastName(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>


                                                <div className="country">
                                                    <div className="input-group" >
                                                        <label className="edit_labels" htmlFor="country">Country</label>
                                                        <input
                                                            required
                                                            type="text"
                                                            className="edit_input"
                                                            value={country}

                                                        />
                                                    </div><div className="input-group">
                                                        <label className="edit_labels">Region</label>
                                                        <input
                                                            required
                                                            value={region}
                                                            type="text"
                                                            placeholder="Region"
                                                            className="edit_input"
                                                            onChange={(e) => {
                                                                setRegion(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="input-group">
                                                        <label className="edit_labels">Zip Code</label>
                                                        <input
                                                            required
                                                            value={zipcode}
                                                            type="number"
                                                            placeholder="Zip Code"
                                                            className="edit_input"
                                                            onChange={(e) => {
                                                                setZip(e.target.value);
                                                            }}
                                                        />

                                                    </div>
                                                </div>


                                                <div className="address">
                                                    <div className="input-group">
                                                        <label className="edit_labels">Cities</label>
                                                        <CityDropdown className="city" cities={cities} onChange={handleCityChange} />
                                                    </div>
                                                    <div className="input-group">
                                                        <label className="edit_labels">Street</label>
                                                        <input
                                                            required
                                                            value={street}
                                                            type="text"
                                                            placeholder="Street"
                                                            className="edit_input"
                                                            onChange={(e) => {
                                                                setStreet(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="input-group">
                                                        <label className="edit_labels">
                                                            Building Name
                                                        </label>
                                                        <input
                                                            required
                                                            value={building_name}
                                                            type="text"
                                                            placeholder="Building Name"
                                                            className="edit_input"
                                                            onChange={(e) => {
                                                                setBuild(e.target.value);
                                                            }}
                                                        />

                                                    </div>
                                                </div>

                                                <div className="input-group">
                                                    <label className="edit_labels">Mobile</label>
                                                    <input
                                                        required
                                                        value={mobile}
                                                        type="text"
                                                        placeholder="Mobile"
                                                        className="edit_input_mobile"
                                                        onChange={(e) => {
                                                            setMobile(e.target.value);
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                            <button type="submit" style={{ margin: "0" }} className="bill_ship_btn">
                                                SUBMIT
                                            </button>
                                        </form>

                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div >
    );
}

export default BillingShippings;

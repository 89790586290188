import axios from "axios";
import { PreoAction } from "./PreOrderReducer";

export const getcategory = () => async (dispatch) => {
  try {
    dispatch(PreoAction.CategoryRequest());
    const data = await axios.get(process.env.REACT_APP_API + "/pre_category");
    dispatch(PreoAction.CategorySuccess(data));
  } catch (error) {
    dispatch(PreoAction.CategoryFail(error));
  }
};

export const GetAllPreoProducts = (limit, page) => async (dispatch) => {
  try {
    dispatch(PreoAction.PreProductRequest());
    const { data } = await axios.get(
      process.env.REACT_APP_API +
        "/pre_product/all_pagination?page=" +
        page +
        "&limit=" +
        limit
    );

    dispatch(PreoAction.PreProductSuccess(data));
  } catch (error) {
    dispatch(PreoAction.PreProductFail(error));
  }
};

// export const getProduct = (categoryName, type) => async (dispatch) => {
//   try {
//     dispatch(PreoAction.productpreRequest());

//     let queryString = "";
//     if (categoryName.length > 0) {
//       queryString = "category=" + categoryName + "&";
//     }

//     const data = await axios.get(
//       process.env.REACT_APP_API +
//         "/product/filter?" +
//         queryString +
//         "type=" +
//         type
//     );

//     dispatch(PreoAction.productpreSuccess(data));
//   } catch (error) {
//     dispatch(PreoAction.productpreFail(error));
//   }
// };
// export const getoneproduct = (id) => async (dispatch) => {
//   try {
//     dispatch(ProductsAction.oneproductRequest());
//     const data = await axios.get(
//       process.env.REACT_APP_API + "/product/single/" + id
//     );
//     dispatch(ProductsAction.oneproductSuccess(data));
//   } catch (error) {
//     dispatch(ProductsAction.oneproductFail(error));
//   }
// };
// export const getfeatureproducg = (id) => async (dispatch) => {
//   try {
//     dispatch(ProductsAction.featureprodRequest());
//     const data = await axios.get(
//       process.env.REACT_APP_API + "/featured_product/" + id
//     );
//     dispatch(ProductsAction.featureprodSuccess(data));
//   } catch (error) {
//     dispatch(ProductsAction.featureprodFail(error));
//   }
// };

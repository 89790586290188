import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cards: [],
  card: [],
  loading: false,
  error: null,
  editedCard : [],
  editedLoading: false,
  editedError: null,
  deletedCard : [],
  deletedLoading: false,
  deletedError: null,
};

const CardSlice = createSlice({
  name: "card",
  initialState,
  reducers: {
    CardRequest(state, action) {
      state.loading = true;
      state.card = [];
      state.error = null;
    },
    CardSuccess(state, action) {
      state.loading = false;
      state.card = action.payload;
      state.error = null;
    },
        CardDeleteSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.cards=[]
    },
    CardFail(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.card = [];
    },

     CardsRequest(state, action) {
      state.loading = true;
      state.cards = [];
      state.error = null;
    },
    CardsSuccess(state, action) {
      state.loading = false;
      state.cards = action.payload;
      state.error = null;
    },
    CardsFail(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.cards = [];
    },


    EditCardRequest(state, action) {
      state.editedLoading = true;
      state.editedCard = [];
      state.editedError = null;
    },
    EditCardSuccess(state, action) {
      state.editedLoading = false;
      state.editedCard = action.payload;
      state.editedError = null;
    },
    EditCardFail(state, action) {
      state.editedLoading = false;
      state.editedError = action.payload;
      state.editedCard = [];
    },
    DeleteCardRequest(state, action) {
      state.deletedLoading = true;
      state.deletedCard = [];
      state.deletedError = null;
    },
    DeleteCardSuccess(state, action) {
      state.deletedLoading = false;
      state.deletedCard = action.payload;
      state.deletedError = null;
    },
    DeleteCardFail(state, action) {
      state.deletedLoading = false;
      state.deletedError = action.payload;
      state.deletedCard = [];
    },

  },
});

export const CardAction = CardSlice.actions;
export default CardSlice;

import { React, useEffect, useState } from "react";
import "./Header.css";
import Navbar from "../Navbar/Navbar";
//images
import logo from "../../assets/Images/Header/tflogodarkgrey (1).png";
import whitelogo from "../../assets/Images/Header/Logo2.png";
import PopUp from "../PopUp/PopUp";

import { RiFacebookCircleLine } from "react-icons/ri";
import { BsInstagram } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";
import { AiFillHeart } from "react-icons/ai";
import { BsFillBagFill } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";
import { AiOutlineHeart } from "react-icons/ai";
import { BsCart2 } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GiHamburgerMenu } from "react-icons/gi";
import isAuth from "../../Utils/isAuth";
import { useLocation } from "react-router-dom";
import SendQuery from "../../Pages/SendQuery/SendQuery";
import { Link } from "react-router-dom";
import MobileHeader from "./MobileHeader";
import { getFavorite } from "../../Redux/homepage/HomePageAction";
import { getMyCards } from "../../Redux/Card/CardActions";
import { SettingsAction } from "../../Redux/settingsRedux";

function Header({ isMainHomeEnded }) {
  const navigate = useNavigate();
  const [logedin, setlogedin] = useState(false);
  const [isshown, setisshown] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [headerin, setheaderin] = useState(false);
  const [open, setOpen] = useState(false);
  const [openInput, setOpenInput] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([dispatch(getFavorite()), dispatch(getMyCards())]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    document.body.style.overflowY = open || isshown ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [open, isshown]);

  const openSalesFunction = () => {
    setOpen(true)
  }

  const { favorite } = useSelector((state) => state.HomeReducer);
  const { cards } = useSelector((state) => state.CardReducer);
  const instagramLink = "https://www.instagram.com/tamer.freres/?hl=en";
  const facebookLink = "https://www.facebook.com/TamerFreres/";
  const handleOpen = () => {
    setOpenInput(!openInput);
  };

  const handleburgerclick = () => {
    setisshown(!isshown);
  };
  useEffect(() => {
    if (isAuth()) {
      setlogedin(true);
    } else {
      setlogedin(false);
    }
  }, [isAuth()]);

  const location = useLocation();
  const currentRoute = location.pathname;

  useEffect(() => {
    if (!isMainHomeEnded && currentRoute === "/") {
      setheaderin(true);
    } else {
      setheaderin(false);
    }
  }, [isMainHomeEnded]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };
  const handleclose = () => {
    setOpen(false);
  };

  const [selectFilter, setSelectedFilter] = useState("");

  const handleSearchKey = (e) => {
    if (e.key === 'Enter') {
      navigate(`/products?search=${selectFilter}`);
      dispatch(SettingsAction.reset())

    } else {
      return
    }
    navigate(`/products?search=${selectFilter}`); // Navigate to the product page
  };

  const handleSearch = () => {
    navigate(`/products?search=${selectFilter}`); // Navigate to the product page
  };

  // Set the filter state based on the query parameter on initial load
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filterParam = searchParams.get("search");
    setSelectedFilter(filterParam || "");
  }, [location.search]);
  return (
    <>
      {headerin ? (
        <>
          {" "}
          <div className={`header ${scroll ? "scroll" : ""}`}>
            <div className="Main_header_section_in" >
              <div className="header_icons_container">
                <Link
                  to="/preorder"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <div className="Saleandpre">Pre-O</div>
                </Link>
                <div
                  className="divider"
                  style={{ border: " 1px solid white " }}
                />
                <div className="Saleandpre1" onClick={() => setOpen(true)}>
                  After Sales
                </div>
              </div>
              <div
                className="header_logo_container"
                onClick={() => navigate("/")}
              >
                <img src={whitelogo} alt="logo" className="header_logo" />
              </div>
              {open && (
                <PopUp
                  setOpen={setOpen}
                  body={<SendQuery isclosed={handleclose} />}
                ></PopUp>
              )}
              {logedin ? (
                <div className="header_icons_container_right1">
                  <div className="search-container">
                    <div className="search-bar">
                      <input
                        type="text"
                        placeholder="Search"
                        className="input-container"
                        value={selectFilter}
                        onChange={(e) => setSelectedFilter(e.target.value)}
                      />

                      <div className="header_icon">
                        <FiSearch
                          className="header-icon"
                          size={25}
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            handleSearch();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="header_icon"
                    onClick={() => navigate("/profile")}
                  >
                    <AiOutlineUser className="header-icons" size={30} />
                  </div>
                  <div
                    className="header_icon"
                    onClick={() => navigate("/Favorite")}
                  >
                    <AiOutlineHeart className="header-icons" size={30} />
                    {favorite?.data?.data.length > 0 && (
                      <div className="favorite-count1">
                        {favorite?.data?.data?.length}
                      </div>
                    )}
                  </div>
                  <div
                    className="header_icon"
                    onClick={() => navigate("/cart")}
                  >
                    <BsCart2 className="header-icons" size={30} />
                    {cards?.data?.length > 0 && (
                      <div className="favorite-count1">
                        {cards?.data?.length}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="header_icons_container_right">
                  <div className="search-container">
                    <div className="search-bar">
                      <input
                        type="text"
                        placeholder="Search"
                        className="input-container"
                        value={selectFilter}
                        onChange={(e) => setSelectedFilter(e.target.value)}

                      />

                      <div className="header_icon">
                        <FiSearch
                          className="header-icons"
                          size={25}
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            handleSearch();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="divider divider-white" />

                  <div
                    className="header_wp_cont"
                    onClick={() => navigate("/sign_in")}
                  >
                    <div className="header_wp_text"> Sign In or Register </div>
                  </div>
                </div>
              )}
              <div
                className="header_hamburger_menu_cont"
                onClick={handleburgerclick}
              >
                <GiHamburgerMenu className="header_hamburger_menu" size={30} />
              </div>
              {isshown && (
                <MobileHeader isShown={isshown} setisshown={setisshown} showSale={openSalesFunction} />
              )}
            </div>
            <Navbar isMainHomeEnded={headerin} />
          </div>
        </>
      ) : (
        <div className={`header ${scroll ? "scroll" : ""}`}>
          <div className="Main_header_section">
            <div className="header_icons_container">
              <div className="header_icon">
                <a
                  href={facebookLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <RiFacebookCircleLine className="header-icons" size={30} />
                </a>{" "}
              </div>
              <div className="header_icon">
                <a
                  href={instagramLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <BsInstagram className="header-icons" size={25} />
                </a>{" "}
              </div>
              <div className="divider" />
              <a
                href="https://api.whatsapp.com/send?phone=96170656040"
                className="wp_a"
                target="_blank"
              >
                <div className="header_wp_cont">
                  <div className="header_icon">
                    <IoLogoWhatsapp className="header-icons" size={25} />
                  </div>
                </div>
              </a>
            </div>
            <div
              className="header_logo_container"
              onClick={() => navigate("/")}
            >
              <img src={logo} alt="logo" className="header_logo1" />
            </div>
            {logedin ? (
              <div className="header_icons_container_right1" style={{ gap: "0.2rem" }}>
                <div className="search-container">
                  <div className="search-bar">
                    <div className="input-container">
                      <input
                        type="text"
                        placeholder={
                          selectFilter
                            ? `Search... (${selectFilter})`
                            : "Search"
                        }
                        className={"search-input2"}
                        value={selectFilter}
                        onChange={(e) => setSelectedFilter(e.target.value)}
                        style={{ color: "black" }}
                        onKeyPress={(e) => {
                          handleSearchKey(e);
                        }}
                      />

                      <div className="header_icon-burger">
                        <FiSearch
                          className="header-icons"
                          size={25}
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            handleSearch();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="header_icon-burger"
                  onClick={() => navigate("/profile")}
                >
                  <AiOutlineUser className="header-icons" size={30} />
                </div>
                <div
                  className="header_icon-burger"
                  onClick={() => navigate("/Favorite")}
                >
                  <AiOutlineHeart className="header-icons" size={30} />
                  {favorite?.data?.data.length > 0 && (
                    <div className="favorite-count">
                      {favorite?.data?.data?.length}
                    </div>
                  )}
                </div>
                <div className="header_icon-burger" onClick={() => navigate("/cart")}>
                  <BsCart2 className="header-icons" size={30} />
                  {cards?.data?.length > 0 && (
                    <div className="favorite-count">{cards?.data?.length}</div>
                  )}
                </div>
              </div>
            ) : (
              <div className="header_icons_container_right">
                <div className="search-container search-bar-dark-theme">
                  <div className="search-bar">
                    <input
                      type="text"
                      placeholder="Search"
                      className="input-container"
                      value={selectFilter}
                      onChange={(e) => setSelectedFilter(e.target.value)}
                    />

                    <div className="header_icon">
                      <FiSearch
                        className="header-icons"
                        size={25}
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                          handleSearch();
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="divider" />

                <div
                  className="header_wp_cont"
                  onClick={() => navigate("/sign_in")}
                >
                  <div className="header_wp_text"> Sign In or Register </div>
                </div>
              </div>
            )}
            <div
              className="header_hamburger_menu_cont"
              onClick={handleburgerclick}
            >
              <GiHamburgerMenu className="header_hamburger_menu" size={30} />
            </div>
            {isshown && (
              <MobileHeader isShown={isshown} setisshown={setisshown} showSale={openSalesFunction} />
            )}
          </div>
          <Navbar />
        </div>
      )
      }
    </>
  );
}

export default Header;

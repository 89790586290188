import { React, useState, useEffect } from "react";
import "./ProductsPage.css";
import WatchesViewCard from "../../Components/WatchesViewCard/WatchesViewCard";
import Filter from "../../Components/Filter/Filter";
import { GetAllProducts } from "../../Redux/Products/ProductsAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import { GetBrands } from "../../Redux/Brand/BrandActions";

function ProductsPage() {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchValue = "search=" + searchParams.get("search");

  const { brands } = useSelector((Store) => Store.BrandReducer);
  const dispatch = useDispatch();

  const limit = 15;
  const [activePage, setActivePage] = useState(1);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(0);
  const query = new URLSearchParams(window.location.search);
  const brand = query.get("brand") !== null ? query.get("brand") : "";
  const category = query.get("category") !== null ? query.get("category") : "";
  const [isopen, setIsopen] = useState(false);

  const { products, loading } = useSelector((Store) => Store.ProductsReducer);

  const [selectFilter, setSelectFilter] = useState("");
  const [targetBrandName, setTargetBrandName] = useState("");

  useEffect(() => {
    for (const item of brands) {
      if (brand) {
        if (item?.id === parseInt(brand)) {
          setTargetBrandName(item?.name);
        }
      }
    }
  }, [brands, brand]);

  useEffect(() => {

    if (searchValue !== "search=null") {
      // If searchValue exists, dispatch with selectFilter
      dispatch(GetAllProducts(limit, activePage, searchValue));
    } else {
      // If searchValue does not exist, dispatch without selectFilter
      dispatch(GetAllProducts(limit, activePage, selectFilter));
    }
  }, [searchValue, selectFilter, activePage]);

  const getFilteredProductsData = (filter) => {
    setSelectFilter(filter);
    dispatch(GetAllProducts(limit, activePage, filter));
  };

  const pagesPerPage = 5;
  const totalPages = products?.totalPages;
  const totalPageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const handlePageChange = (pageNum) => {
    setActivePage(pageNum);
  };

  useEffect(() => {
    dispatch(GetBrands());
  }, []);

  const renderPageNumbers = () => {
    const start = activePage - 1 - ((activePage - 1) % pagesPerPage);
    const visiblePageNumbers = totalPageNumbers.slice(
      start,
      start + pagesPerPage
    );

    return visiblePageNumbers.map((pageNum) => (
      <span
        key={pageNum}
        className={`pagination_container_item ${pageNum === activePage ? "pagination_container_item_active" : ""
          }`}
        onClick={() => handlePageChange(pageNum)}
      >
        {pageNum}
      </span>
    ));
  };

  const handleBackClick = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const handleNextClick = () => {
    if (activePage < totalPages) {
      setActivePage(activePage + 1);
    }
  };

  useEffect(() => {
    if (products != undefined) {
      let start = (activePage - 1) * limit + 1;
      let end = products?.totalItems;
      if (limit < products?.totalItems) {
        end = limit * activePage;
        if (end > products?.totalItems) {
          end = products?.totalItems;
        }
      }
      setStartPage(start);
      setEndPage(end);
    }
  }, [products]);

  return (
    <div className="Main_Products">

      <div className="Products_header">
        <span className="section_route"> Home / Brands /</span>{" "}
        <span className="section_name">{targetBrandName} </span>
      </div>
      <div className="Products_container_filters">
        <div className="filter_container">
          <div className="filter_conts">
            <Filter
              brand={brand}
              category={category}
              getFilteredProducts={getFilteredProductsData}
              setIsopen={setIsopen}
              isopen={isopen}

            />
          </div>
          <div className="filter_container_title">
            {" "}
            Showing {products?.currentPage * 15} Of Results{" "}
            {products?.count}
          </div>

        </div>
      </div>
      {isopen && <div className="blackspace" onClick={() => setIsopen(false)}></div>}
      {loading ? (
        <Loader />
      ) : (
        <div className="Products_container_products">
          <div className="Products_container">

            {products?.data?.map((item) => (
              <WatchesViewCard
                image={item?.img_url}
                name={item?.title}
                brand={item?.brand?.name}
                price={item?.price}
                quantity_limit={parseFloat(item?.quantity_limit)}
                key={item?.id}
                type={item?.type}
                link={item?.id}
                id={item?.brand_id}
              />
            ))}
          </div>
        </div>
      )}
      <div className="pagination_container">
        <span
          key="backPage"
          className="pagination_container_item"
          onClick={handleBackClick}
        >
          Back
        </span>

        {renderPageNumbers()}

        <span
          key="nextPage"
          className="pagination_container_item"
          onClick={handleNextClick}
        >
          Next
        </span>
      </div>
    </div>
  );
}

export default ProductsPage;

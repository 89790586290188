import { React, useState  , useEffect} from "react";
import "./Apply.css";
import { useDispatch, useSelector } from "react-redux";
import { applyJob } from "../../Redux/Apply/ApplyActions";

function Apply(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [cv, setCv] = useState();
  const dispatch = useDispatch();
  const { loading ,apply } = useSelector((Store) => Store.ApplyReducer);

  
  const handleDrop = (event) => {
    event.preventDefault();
    setCv(event.dataTransfer.files[0]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleCvChange = (e) => {
    const file = e.target.files[0];
    setCv(file);
  };

  const saveApply = (e) => {
    e.preventDefault();

    const formData = {
      first_name: firstName,
      last_name: lastName,
      mobile: mobile,
      email: email,
      cv: cv,
    };
   
    dispatch(applyJob(props.jobId , formData));
    setLastName("");
    setFirstName("");
    setEmail("");
    setMobile("");
    props.setOpen(false);
  };

  return (
    <>
      <div className="apply_main_div">
        <form onSubmit={(e) => saveApply(e)}>
          <div className="apply_title"> PERSONAL INFORMATION</div>
          <div className="apply_form_row">
            <div className="apply_form_row_col">
              <label className="apply_form_labels">First Name *</label>
              <input
                required
                value={firstName}
                type="text"
                placeholder="First Name"
                className="apply_input"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </div>
            <div className="apply_form_row_col">
              <label className="apply_form_labels">Last Name *</label>
              <input
                required
                value={lastName}
                type="text"
                placeholder="Last Name"
                className="apply_input"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="apply_form_row">
            <div className="apply_form_row_col">
              <label className="apply_form_labels">Email *</label>
              <input
                required
                value={email}
                type="email"
                placeholder="Email"
                className="apply_input"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="apply_form_row_col">
              <label className="apply_form_labels">Mobile Number *</label>
              <input
                required
                value={mobile}
                type="text"
                placeholder="Mobile Number"
                className="apply_input"
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="apply_form_row">
            <div className="apply_form_row_col1">
              <label className="apply_form_labels">Upload Cv *</label>
              <div
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                style={{ border: "2px dashed #ccc", padding: "20px" }}
              >
                <p>Drag and drop a file here</p>
                <input
                  required
                  type="file"
                  name="cv"
                  onChange={(e) => handleCvChange(e)}
                  className="apply_input"
                />
                <p>Selected File: {cv ? cv.name : "None"}</p>
              </div>
            </div>
          </div>
          <div className="apply_form_row apply_form_row_submit_btn">
            <button className="submit_btn">submit application</button>
          </div>
        </form>
      </div>
    </>
  );
}

export default Apply;

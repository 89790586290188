import React, { useState, useEffect } from "react";
import { forgetPassword } from "../../Redux/Login/Register/LoginActions";
import { useDispatch, useSelector } from "react-redux";
import "./PasswordResetRequest.css";
import { useNavigate } from "react-router-dom";

function PasswordResetPage() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const dispatch = useDispatch();
  const { forgetPassMessageError } = useSelector(
    (Store) => Store.userReducer
  );
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setErrorMessage("");
  }, []);

  useEffect(() => {
    if (forgetPassMessageError != "") {
      setErrorMessage(forgetPassMessageError);
    }
  }, [forgetPassMessageError]);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      dispatch(forgetPassword(email, otp, newPassword , navigate));
    } catch (error) {}
  };

  return (
    <div>
      <form onSubmit={handleResetPassword} className="reset_password_form">
        <input
          type="email"
          placeholder="Enter your email"
          className="email_input_reset_pass"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Enter OTP"
          value={otp}
          className="email_input_reset_pass"
          onChange={(e) => setOtp(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Enter your new password"
          className="email_input_reset_pass"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        {errorMessage && <div className="error_msg">{errorMessage}</div>}

        <button type="submit" className="btn_reset_pass">
          Reset Password
        </button>
      </form>
    </div>
  );
}

export default PasswordResetPage;

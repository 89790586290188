import { React, useState, useEffect, useRef } from "react";
import "./Navbar.css";
import { useLocation } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import { GetBrands } from "../../Redux/Brand/BrandActions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GetCategories } from "../../Redux/Category/CategoryActions";

function Navbar({ isMainHomeEnded }) {
  const dispatch = useDispatch();
  const { brands, loading } = useSelector((Store) => Store.BrandReducer);
  const { categories } = useSelector((Store) => Store.CategoryReducer);

  useEffect(() => {
    dispatch(GetBrands());
    dispatch(GetCategories())
  }, [dispatch]);



  const [activeSection, setActiveSection] = useState("");

  const handleOnClick = (section) => {
    setActiveSection(section);
    window.location.href = `/products?brand=${section}`;
  };
  const [headerin, setheaderin] = useState(false);
  const [brandlist, setbrandlist] = useState(false);
  const [categorieslist, setCategorieslist] = useState(false);
  const location = useLocation();
  const currentRoute = location.pathname;

  useEffect(() => {
    const sectionFromUrl = new URLSearchParams(window.location.search).get(
      "section"
    );
    setActiveSection(sectionFromUrl || "");
  }, [window.location.search]);

  useEffect(() => {
    if (isMainHomeEnded && currentRoute === "/") {
      setheaderin(true);
    } else {
      setheaderin(false);
    }
  }, [isMainHomeEnded]);
  useEffect(() => {
    if (activeSection === "Brands") {
      setbrandlist(true);
    } else {
      setbrandlist(false);
    }
  }, [activeSection]);
  function handleOnClick1(section) {
    // setActiveSection(e.target.value);
    window.location.href = `/products?categor=${section}`;
  }

  function handleOnClickBrand(section) {
    // setActiveSection(e.target.value);
    window.location.href = `/products?brand=${section}`;
  }



  const [currenturl, setcurrenturl] = useState(true);
  useEffect(() => {
    const currentURL = window.location.href;
    const urlObject = new URL(currentURL);
    const routeString = urlObject.pathname;
    setcurrenturl(routeString === "/preorder" ? false : true);
  }, [window.location.href]);

  const currentURL = window.location.href;
  const urlObject = new URL(currentURL);
  const routeString = urlObject.pathname;



  const dropdownRef = useRef(null);
  const dropdownRefCategory = useRef(null);


  useEffect(() => {
    // Function to handle clicks outside the dropdown
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setbrandlist(false);
      }
    };

    const handleOutsideClick1 = (event) => {
      if (dropdownRefCategory.current && !dropdownRefCategory.current.contains(event.target)) {
        setCategorieslist(false);
      }
    };

    // Attach the event listener when the dropdown is open
    if (brandlist) {
      document.addEventListener('click', handleOutsideClick);
    }
    if (categorieslist) {
      document.addEventListener('click', handleOutsideClick1);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('click', handleOutsideClick1);
    };
  }, [brandlist, categorieslist]);

  const handledropdown = () => {
    // Toggle the brandlist state to show/hide the dropdown
    setbrandlist(!brandlist);
  };

  return (
    <>
      {headerin ? (
        <>

          {!loading && (<div className="Main_navbar_login_in">
            <div className="navbar_container">
              <nav className="navbar">
                {/* {brands.map(
                (brand) =>
                  brand.isFav && (
                    <Link
                      to={`/products?brand=${brand?.id}`}
                      className="row_content_item_nav"
                      key={brand.id}
                    >
                      {brand.name}
                    </Link>
                  )
              )} */}

                <div
                  onClick={(e) => handledropdown()}
                  className="active"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  BRANDS <MdKeyboardArrowDown size={30} />
                </div>
                &nbsp; &nbsp; &nbsp; &nbsp;

                <a
                  href={`products?brand=${brands.find(e => e.name === "Montblanc")?.id}`}

                  className="active"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white"
                  }}
                >
                  LUXURY WATCHES
                </a>
                &nbsp; &nbsp; &nbsp; &nbsp;

                <a
                  href={`products?brand=${brands.find(e => e.name === "Swatch")?.id}`}

                  className="active"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white"
                  }}
                >
                  FASHION WATCHES
                </a>
                &nbsp; &nbsp;  &nbsp; &nbsp;
                <a
                  href={`products?brand=${brands.find(e => e.name === "Flik Flak")?.id}`}

                  className="active"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white"
                  }}
                >
                  KIDS WATCHES
                </a>
                &nbsp; &nbsp;  &nbsp; &nbsp;
                <div
                  onClick={(e) =>
                    setCategorieslist(!categorieslist)

                  }
                  className="active"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  LIFESTYLE ACCESSORIES <MdKeyboardArrowDown size={30} />
                </div>
                &nbsp; &nbsp;  &nbsp; &nbsp;
                <a
                  href={`products?category=${categories.find(e => e.name === "Travel")?.id}`}

                  className="active"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white"
                  }}
                >
                  TRAVEL
                </a>
                &nbsp; &nbsp;
              </nav>
              {brandlist && (
                <div ref={dropdownRef} className="brands_dropdown111">
                  <div className="brands_dropdown11111">
                    {brands?.slice(0, 2).map((section) => (
                      <a
                        key={section.id}
                        className={`navbar__item ${activeSection === section.id ? "active" : ""
                          }`}
                        onClick={() => handleOnClickBrand(section.id)}
                        style={{ color: "black" }}
                      >
                        {section.name}
                      </a>
                    ))}
                  </div>
                  <div className="brands_dropdown11111">
                    {brands?.slice(2, 5).map((section) => (
                      <a
                        key={section.id}
                        className={`navbar__item ${activeSection === section.id ? "active" : ""
                          }`}
                        onClick={() => handleOnClickBrand(section.id)}
                        style={{ color: "black" }}
                      >
                        {section.name}
                      </a>
                    ))}
                  </div>
                  <div className="brands_dropdown11111">
                    {brands?.slice(5, 8).map((section) => (
                      <a
                        key={section.id}
                        className={`navbar__item ${activeSection === section.id ? "active" : ""
                          }`}
                        onClick={() => handleOnClickBrand(section.id)}
                        style={{ color: "black" }}
                      >
                        {section.name}
                      </a>
                    ))}
                  </div>
                </div>
              )}
              {categorieslist && (
                <div ref={dropdownRefCategory} className="brands_dropdown111">
                  <div className="brands_dropdown11111">
                    {categories?.slice(0, 5).map((section) => (
                      <a
                        key={section.id}
                        className={`navbar__item ${activeSection === section.id ? "active" : ""
                          }`}
                        onClick={() => handleOnClick1(section.id)}
                        style={{ color: "black" }}
                      >
                        {section.name}
                      </a>
                    ))}
                  </div>
                  <div className="brands_dropdown11111">
                    {categories?.slice(5, 10).map((section) => (
                      <a
                        key={section.id}
                        className={`navbar__item ${activeSection === section.id ? "active" : ""
                          }`}
                        onClick={() => handleOnClick1(section.id)}
                        style={{ color: "black" }}
                      >
                        {section.name}
                      </a>
                    ))}
                  </div>
                  <div className="brands_dropdown11111">
                    {categories?.slice(10, categories.length - 1).map((section) => (
                      <a
                        key={section.id}
                        className={`navbar__item ${activeSection === section.id ? "active" : ""
                          }`}
                        onClick={() => handleOnClick1(section.id)}
                        style={{ color: "black" }}
                      >
                        {section.name}
                      </a>
                    ))}
                  </div>
                </div>
              )}



            </div >
          </div >)}
        </>

      ) : (
        <div className="Main_navbar_login">
          <div className="navbar_container1">
            {currenturl && (
              <nav className="navbar1">
                {brands.map((brand) => (
                  <Link
                    to={`/products?brand=${brand?.id}`}
                    className="row_content_item_nav1"
                    key={brand.id}
                  >
                    {brand.name}
                  </Link>
                ))}
              </nav>
            )}
            {brandlist && (
              <div className="brands_dropdown1">
                {brands?.map((section) => (
                  <a
                    key={section}
                    className={`navbar__item ${activeSection === section ? "active" : ""
                      }`}
                    onClick={() => handleOnClick1(section)}
                    style={{ color: "black" }}
                  >
                    {section.name}
                  </a>
                ))}
              </div>
            )}

            {categorieslist && (
              <div className="brands_dropdown1">
                {categories?.map((section) => (
                  <a
                    key={section}
                    className={`navbar__item ${activeSection === section ? "active" : ""
                      }`}
                    onClick={() => handleOnClick1(section)}
                    style={{ color: "black" }}
                  >
                    {section.name}
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
      )
      }
    </>
  );
}

export default Navbar;

import axios from "axios";
import { FeaturedAction } from "./FeaturedReducer";

export const GetFeatured = () => async (dispatch) => {
    try {
        dispatch(FeaturedAction.FeaturedRequest());
        const { data } = await axios.get(
            process.env.REACT_APP_API + "/featured_brand"
        );
        dispatch(FeaturedAction.FeaturedSuccess(data.data));
    } catch (error) {
        dispatch(FeaturedAction.FeaturedFail(error));
    }
};

import axios from "axios";
import { CareerAction } from "./CareerReducers";

export const career = (CareerData) => async (dispatch) => {
  try {
    const formData = {
      first_name: CareerData.first_name,
      last_name: CareerData.last_name,
      mobile: CareerData.mobile,
      email: CareerData.email,
      position: CareerData.position,
      tell_us: CareerData.tell_us,
      img_url: CareerData.img_url,
      cv: CareerData.cv,
    };

    dispatch(CareerAction.CareerRequest());
    const { data } = await axios.post(
      process.env.REACT_APP_API + "/career",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    dispatch(CareerAction.CareerSuccess(data.data));
  } catch (error) {
    dispatch(CareerAction.CareerFail(error));
  }
};

import "./Storyandnewmodal.css";
import React, { useRef, useEffect, useState } from "react";
import { getonestory } from "./../../Redux/homepage/HomePageAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";
import moment from "moment";

function SendQuery(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getonestory(id));
  }, [dispatch]);
  const { onestory } = useSelector((state) => state.HomeReducer);

  return (
    <>
      <div className="click_and_collect_main_container">
        <div className="click_and_collect_main_tite">News And Story</div>
        <div className="click_and_collect_content">
          {moment(onestory?.newsAndStory?.createdAt).format("YYYY-MM-DD")}
          <img
            src={
              process.env.REACT_APP_API +
              "/news/" +
              onestory?.newsAndStory?.image
            }
            style={{ marginTop: "2rem", width: "100%" }}
          />
          <div className="click_and_collect_labels">
            {onestory?.newsAndStory?.title}
          </div>
          <div className="text-cont-desc">
            {onestory?.newsAndStory?.description}
          </div>
        </div>
      </div>
    </>
  );
}

export default SendQuery;

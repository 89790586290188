import { React, useState, useEffect } from "react";
import "./Careers.css";
import career1 from "../../assets/Images/Careers/career_1.png";
import career2 from "../../assets/Images/Careers/career_2.png";
import { career } from "../../Redux/Career/CareerActions";
import { GetJobs } from "../../Redux/Job/JobActions";
import { useDispatch, useSelector } from "react-redux";
import PopUp from "../../Components/PopUp/PopUp";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Apply from "./Apply";
import VacanciesDetailedForm from "./VacanciesDetailedForm";

function Careers() {
  const [cv, setCv] = useState();
  const [imgUrl, setImgUrl] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [position, setPosition] = useState("");
  const [specialRequest, setSpecialRequest] = useState("");
  const dispatch = useDispatch();
  const { error, loading } = useSelector((Store) => Store.careerReducer);
  const { jobs } = useSelector((Store) => Store.JobReducer);
  const [errorMessage, setErrorMessage] = useState("");
  const [notificationSubmit, setNotificationSubmit] = useState(false);
  const [jobsData, setJobsData] = useState([]);
  const [applyForm, openApplyForm] = useState(false);
  const [detailsForm, openDetailsForm] = useState(false);
  const [jobId, setJobId] = useState();
  const [jobTitle, setJobTitle] = useState("");
  const [country, setcountry] = useState("");
  const [type, settype] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setCv(file);
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImgUrl(file);
  };

  const applyHandleActions = () => {
    openDetailsForm(false);
    openApplyForm(true);
  };

  useEffect(() => {
    if (error != "") {
      setErrorMessage(error);
    }
  }, [error]);

  useEffect(() => {
    dispatch(
      GetJobs(
        jobTitle ? jobTitle : "",
        country ? country : "",
        type ? type : ""
      )
    );
  }, [jobTitle, country, type]);

  useEffect(() => {
    if (jobs) {
      setJobsData(jobs);
    }
  }, [jobs]);

  const saveCareer = (e) => {
    e.preventDefault();

    const user = {
      first_name: firstName,
      last_name: lastName,
      mobile: phone,
      email: email,
      position: position,
      tell_us: specialRequest,
      img_url: imgUrl,
      cv: cv,
    };
    setErrorMessage("");
    dispatch(career(user));
    setNotificationSubmit(true);
    setLastName("");
    setFirstName("");
    setEmail("");
    setPhone("");
    setPosition("");
    setSpecialRequest("");
  };
  document.addEventListener("DOMContentLoaded", function () {
    document.getElementById("scrollButton").addEventListener("click", function () {
      const targetDiv = document.getElementById("targetDiv");
      if (targetDiv) {
        targetDiv.scrollIntoView({ behavior: "smooth" });
      }
    });
  });
  document.addEventListener("DOMContentLoaded", function () {
    const button = document.getElementById("customButton");
    const targetDiv = document.getElementById("customTargetDiv");

    if (targetDiv) {
      button.addEventListener("click", function () {
        targetDiv.scrollIntoView({ behavior: "smooth" });
      });
    }
  });

  return (
    <>
      <div className="career_container_main">
        <div className="header-wrapper">
          <img
            src={career1}
            alt="image"
            className="career_img"
            draggable={false}
          />
          <div className="join_team_text">
            <div className="careers_title">careers</div>
            <div className="join_title">Join Our Team</div>
            <div className="join_details">
              we pride ourselves on creating a unique and exclusive working
              experience. We are always looking for talented individuals to join
              us in crafting luxury experiences that exceed our customers'
              expectations. If you are looking to take your career to the next
              level
            </div>
          </div>
        </div>


        <div className="career_container_second_div">
          <div className="career_container_second_div_left">
            <div className="career_container_second_title">
              Why Tamer Frères SAL?
            </div>
            <div className="career_container_second_content">
              <p>Tamer Frères brings your vision to reality.</p>
              <p>
                {" "}
                With our diversified activities and divisions, we help you make
                the most of your talents, and by developing them, we provide you
                with the proper career opportunities.
              </p>
              <p>
                If you believe in Respect, Integrity, Accountability, Teamwork,
                Passion and Personal Initiative, you can apply and be part of
                our modern and vibrant company.
              </p>
              <div className="buttons_div">
                <button className="career_btn" id="scrollButton">
                  Check our vacancies
                </button>
                <button className="career_btn" id="customButton">
                  Upload your cv
                </button>
              </div>
            </div>
          </div>
          <div className="career_container_second_div_right">
            <img
              src={career2}
              alt="image"
              className="career2_img"
              draggable={false}
            />
          </div>
        </div>
        <form
          className="career_form"
          onSubmit={(e) => saveCareer(e)}
          id="customTargetDiv"
        >
          <div className="career_form_div">
            <div className="career_form_title">
              <div className="career_form_title_1">
                We are always looking for outstanding people
              </div>
            </div>
            <div className="career_form_row">
              <div className="career_form_row_col">
                <label className="career_form_labels">First Name</label>
                <input
                  required
                  value={firstName}
                  type="text"
                  placeholder="First Name"
                  className="career_input"
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </div>
              <div className="career_form_row_col">
                <label className="career_form_labels">Last Name</label>
                <input
                  required
                  value={lastName}
                  type="text"
                  placeholder="Last Name"
                  className="career_input"
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="career_form_row">
              <div className="career_form_row_col">
                <label className="career_form_labels">Email Address</label>
                <input
                  required
                  value={email}
                  type="text"
                  placeholder="Email Address"
                  className="career_input"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="career_form_row_col">
                <label className="career_form_labels">Phone Number</label>
                <input
                  required
                  value={phone}
                  type="text"
                  placeholder="Phone Number"
                  className="career_input"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="career_form_row">
              <div className="career_form_row_col">
                <label className="career_form_labels">
                  Position Applied For
                </label>
                <input
                  required
                  value={position}
                  type="text"
                  placeholder="Position Applied For"
                  className="career_input"
                  onChange={(e) => {
                    setPosition(e.target.value);
                  }}
                />
              </div>
              <div className="career_form_row_col1">
                <div className="career_form_row_col">
                  <label className="career_form_labels">
                    Curriculum Vitae{" "}
                  </label>
                  <div className="center-input">
                    <input
                      required
                      type="file"
                      name="cv"
                      onChange={(e) => handleFileChange(e)}
                      placeholder="Upload Your Resume / CV"
                      accept=".doc,.docx,application/pdf"
                      className="career_input"
                    />
                  </div>

                </div>
                <div className="career_form_row_col" style={{ marginRight: 0 }}>
                  <label className="career_form_labels" style={{ marginRight: 0 }}>Profile Picture </label>


                  <div className="center-input">


                    <input
                      required
                      name="img_url"
                      onChange={(e) => handleImageChange(e)}
                      type="file"
                      placeholder="Upload Your Picture"
                      accept="image/png, image/gif, image/jpeg"
                      className="career_input career_input_margin"
                    />

                  </div>

                </div>
              </div>
            </div>
            <div className="career_form_row" style={{ width: "100% -3rem" }}>
              <div className="career_form_row_col2">
                <label className="career_form_labels">Special Request</label>
                <textarea
                  required
                  value={specialRequest}
                  type="text"
                  placeholder="Special Request"
                  className="career_input career_textarea"
                  onChange={(e) => {
                    setSpecialRequest(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="career_form_row career_form_row_submit_btn">
              <button className="submit_btn">submit application</button>
            </div>
          </div>
        </form>

        <div className="check_vacancies_div" id="targetDiv">
          <div className="check_vacancies_title">Check Our Vacancies</div>

          <div className="check_vacancies_row">
            <div className="check_vacancies_row_col">
              <label className="check_vacancies_labels">Keywords</label>
              <input
                type="text"
                placeholder="Keywords"
                className="check_vacancies_input"
                onChange={(e) => {
                  setJobTitle(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="check_vacancies_row">
            <div className="check_vacancies_row_col">
              <label className="check_vacancies_labels">Location</label>
              <input
                type="text"
                placeholder="Location"
                className="check_vacancies_input"
                onChange={(e) => {
                  setcountry(e.target.value);
                }}
              />
            </div>
          </div>
          {/* <div className="check_vacancies_row">
            <div className="check_vacancies_checkbox_row_col">
              <input
                type="checkbox"
                name="remote_job"
                id="remote_job"
                value="remote_job"
                className="checkbox"
              />
              <label htmlFor="remote_job" className="label_check">
                Remote Job Only
              </label>
            </div>
          </div> */}
        </div>
        {jobsData && (
          <div className="vacancies_table_div">
            <div className="check_vacancies_title">Vacancies</div>

            <div className="table-center-container">

              <table className="vacancy_table">
                <thead className="table_thead">
                  <tr className="table_header">
                    <td>Job Title</td>
                    <td>Division</td>
                    <td>Country</td>
                    <td>Posting Date</td>
                    <td>Closing Date</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>

                  {jobsData.map((job, i) => (
                    <tr key={i} className="table_content">
                      <td
                        className="job_title_field"
                        onClick={() => {
                          openDetailsForm(true);
                          setJobId(job.id);
                        }}
                      >
                        {job.title}
                      </td>
                      <td>{job.division}</td>
                      <td>{job.country}</td>
                      <td>{job.posting_date}</td>
                      <td>{job.end_date}</td>
                      <td
                        className="apply_action"
                        onClick={() => {
                          openApplyForm(true);
                          setJobId(job.id);
                        }}
                      >
                        {" "}
                        <AiOutlinePlusCircle className="plus_sign" size={20} />
                        <div className="apply_div">Apply</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

          </div>
        )}
      </div>

      {notificationSubmit && !loading && errorMessage !== "" && (
        <PopUp
          setOpen={setNotificationSubmit}
          body={<div className="popup_title">Error when saving Data!!</div>}
        />
      )}
      {applyForm && (
        <PopUp
          setOpen={openApplyForm}
          body={<Apply jobId={jobId} setOpen={openApplyForm} />}
        />
      )}

      {detailsForm && (
        <PopUp
          setOpen={openDetailsForm}
          body={
            <VacanciesDetailedForm
              jobId={jobId}
              setOpen={openDetailsForm}
              applyHandle={applyHandleActions}
            />
          }
        />
      )}
    </>
  );
}

export default Careers;

import React, { useState } from 'react';

const CityDropdown = ({ cities, onChange }) => {
  const [selectedCity, setSelectedCity] = useState('');

  const handleCityChange = (event) => {
    const newCity = event.target.value;
    setSelectedCity(newCity);
    onChange(newCity); // Call the onChange callback with the new city
  };

  return (
    <div>
      <select value={selectedCity} onChange={handleCityChange}
        style={{
          marginBottom: '16px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          boxSizing: 'border-box',
          display: 'flex',
          // flexDirection: 'column', 
          justifyContent: 'space-between',
          fontFamily: 'DM Sans',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '150%',
          letterSpacing: '0.02em',
          color: '#2f2f2f',
          height: '2.6rem',
          paddingLeft: '15px',
          marginBottom: '5px',
        }}

      >
        <option value="">Select a city</option>
        {cities.map((city, index) => (
          <option key={index} value={city}>
            {city}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CityDropdown;

import axios from "axios";
import { CategoryAction } from "./CategoryReducers";

export const GetCategories = () => async (dispatch) => {
  try {
    dispatch(CategoryAction.CategoryRequest());
    const { data } = await axios.get(
      process.env.REACT_APP_API + "/category"
    );
    dispatch(CategoryAction.CategorySuccess(data.data));
  } catch (error) {
    dispatch(CategoryAction.CategoryFail(error));
  }
};

// export const GetSubCategories = (id) => async (dispatch) => {
//   try {
//     dispatch(CategoryAction.SubCategoryRequest());
//     const { data } = await axios.get(
//       process.env.REACT_APP_API + "/sub_category/"+id
//     );
//     console.log("me",data)
//     dispatch(CategoryAction.SubCategorySuccess(data.data));
//   } catch (error) {
//     dispatch(CategoryAction.SubCategoryFail(error));
//   }
// };
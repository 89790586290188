import axios from "axios";
import { OrderAction } from "./OrderReducers";

export const GetOrders = () => async (dispatch) => {
  try {
    dispatch(OrderAction.OrdersRequest());
    const { data } = await axios.get(
      process.env.REACT_APP_API + "/order/token"
    );
    dispatch(OrderAction.OrdersSuccess(data.data));
  } catch (error) {
    dispatch(OrderAction.OrdersFail(error));
  }
};

export const GetOrderByNumber = (order_number) => async (dispatch) => {
  try {
    dispatch(OrderAction.OrderRequest());
    const { data } = await axios.get(
      process.env.REACT_APP_API + "/order/" + order_number
    );
    dispatch(OrderAction.OrderSuccess(data.data));
  } catch (error) {
    dispatch(OrderAction.OrderFail(error));
  }
};


import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    featured: [],

    loading: false,
    error: null,
};

const FeaturedSlice = createSlice({
    name: "featured",
    initialState,
    reducers: {
        FeaturedRequest(state, action) {
            state.loading = true;
            state.featured = [];
            state.error = null;
        },
        FeaturedSuccess(state, action) {
            state.loading = false;
            state.featured = action.payload;
            state.error = null;
        },
        FeaturedFail(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.featured = [];
        },
    },
});

export const FeaturedAction = FeaturedSlice.actions;
export default FeaturedSlice;

import { React, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import "./Section1.css";
import watch1 from "../../../assets/Images/Home/watch1.jpg";
import watch2 from "../../../assets/Images/Home/watch2.jpg";
import mbvideo from "../../../assets/Images/Home/Montblanc-Extreme-Video 1.jpg";
import watch3 from "../../../assets/Images/Home/IMAGE (1).png";
import { useNavigate } from "react-router-dom";
import { getfeature } from "./../../../Redux/homepage/HomePageAction";

function Section1() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [source, setSource] = useState("");

  useEffect(() => {
    dispatch(getfeature());
  }, [dispatch]);
  const { feature } = useSelector((state) => state.HomeReducer);
  const [record, setRecord] = useState([]);
  useEffect(() => {
    setRecord(feature);
  }, [feature]);

  return (
    <div className="Main_Section1_div">
      <div className="Main_Section1_Container">
        <div className="div1_cont">
          <div className="image-container">
            <img src={record[0]?.img_url} alt="watch1" className="image_sec" />
          </div>
          <div className="text-container">
            <div className="text-container1">{record[0]?.title}</div>
            <div className="text-container2">{record[0]?.description}</div>
            <div
              className="text-container31"
              onClick={() => navigate(`/featured_items/${record[0]?.id}`)}
            >
              <button className="buttonofcont3">DISCOVER MORE</button>
            </div>
          </div>
        </div>
        <div className="div2_cont">
          <div className="image-container">
            <img src={record[1]?.img_url} alt="watch1" className="image_sec2" />
          </div>
          <div className="text-container">
            <div className="text-container1">{record[1]?.title}</div>
            <div className="text-container2">{record[1]?.description}</div>
            <div
              className="text-container31"
              onClick={() => navigate(`/featured_items/${record[1]?.id}`)}
            >
              <button className="buttonofcont3">DISCOVER MORE</button>
            </div>
          </div>
        </div>
      </div>
      <div className="Main_Section2_Container">
        <div className="div2_cont">
          <div className="image-container">
            <img src={record[2]?.img_url} alt="watch1" className="image_sec2" />
          </div>
          <div className="text-container">
            <div className="text-container1">{record[2]?.title}</div>
            <div className="text-container2">{record[2]?.description}</div>
            <div
              className="text-container31"
              onClick={() => navigate(`/featured_items/${record[2]?.id}`)}
            >
              <button className="buttonofcont3">DISCOVER MORE</button>
            </div>
          </div>
        </div>
        <div className="div1_cont">
          <div className="image-container">
            <img src={record[3]?.img_url} alt="watch1" className="image_sec" />
          </div>
          <div className="text-container">
            <div className="text-container1">{record[3]?.title}</div>
            <div className="text-container2">{record[3]?.description}</div>
            <div
              className="text-container31"
              onClick={() => navigate(`/featured_items/${record[3]?.id}`)}
            >
              <button className="buttonofcont3">DISCOVER MORE</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section1;

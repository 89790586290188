import axios from "axios";
import { GiftAction } from "./GiftReducer";
export const AddGift =
  (id, special_instructions, billing_id, shipping_id) => async (dispatch) => {
    try {
      dispatch(GiftAction.GiftRequest());
      const { data } = await axios.post(
        process.env.REACT_APP_API + "/gifts/create/" + id,
        {
          special_instructions,
          billing_id,
          shipping_id,
        }
      );
      dispatch(GiftAction.GiftSuccess(data));
    } catch (error) {
      dispatch(GiftAction.GiftFail(error));
    }
  };

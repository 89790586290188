import axios from "axios";
import { userAction } from "./LoginReducers";
import setauthToken from "../../../Utils/setAuthToken";
import Cookies from "js-cookie";

export const Register =
  (email, firstName, lastName, password, navigate) => async (dispatch) => {
    try {
      dispatch(userAction.RegisterRequest());
      const { data } = await axios.post(
        process.env.REACT_APP_API + "/create_account",
        {
          email: email,
          password: password,
          first_name: firstName,
          last_name: lastName,
        }
      );
      dispatch(userAction.RegisterSuccess(data));
      localStorage.setItem("token", data.token);
      localStorage.setItem("id", data.data.id);
      setauthToken(data.token);
    } catch (error) {
      dispatch(userAction.RegisterFail(error));
    }
  };

export const Login =
  (email, password, rememberme, navigate) => async (dispatch) => {
    try {
      dispatch(userAction.LoginRequest());
      const { data } = await axios.post(process.env.REACT_APP_API + "/login", {
        email: email,
        password: password,
        remember_me: rememberme,
      });
      dispatch(userAction.LoginSuccess(data));
      localStorage.setItem("token", data.Token);
      localStorage.setItem("id", data.data.id);
      setauthToken(data.Token);
      navigate("/");
    } catch (error) {
      dispatch(userAction.LoginFail(error));
    }
  };
export const Logout = (navigate) => async (dispatch) => {
  localStorage.clear();
  setauthToken(false);
  Cookies.remove("token"); // Replace with the name of the cookie you want to remove

  navigate("/");
};
export const GetInfo = () => async (dispatch) => {
  try {
    const { data } = await axios.get(process.env.REACT_APP_API + "/GetMyInfo");
    dispatch(userAction.RegisterSuccess(data.data));
  } catch (error) {

  }
};
export const GetProtected = () => async (dispatch) => {
  try {

    dispatch(userAction.ProtectedRequest())
    const res = await axios.get(process.env.REACT_APP_API + "/protected1");

    dispatch(userAction.ProtectedSuccess(res.data));
  } catch (error) {
    dispatch(userAction.ProtectedFail(error))
  }
};


export const editProfile =
  (firstName, lastName, gender) => async (dispatch) => {
    try {
      dispatch(userAction.EditRequest());
      const { data } = await axios.patch(
        process.env.REACT_APP_API + "/CompleteProfile",
        {
          first_name: firstName,
          last_name: lastName,
          gender: gender,
        }
      );
      dispatch(userAction.EditSuccess(data.data));
    } catch (error) {
      dispatch(userAction.EditFail(error));
    }
  };

export const SendOtp = (email, navigate) => async (dispatch) => {
  try {
    dispatch(userAction.sendOtpRequest());
    const { data } = await axios.post(process.env.REACT_APP_API + "/send_otp", {
      email: email,
    });
    dispatch(userAction.sendOtpSuccess());
    navigate("/reset_page");
  } catch (error) {
    dispatch(userAction.sendOtpFail());

  }
};

export const forgetPassword =
  (email, otp, newPassword, navigate) => async (dispatch) => {
    try {
      dispatch(userAction.forgetPasswordRequest());
      const { data } = await axios.post(
        process.env.REACT_APP_API + "/forget_password",
        {
          email: email,
          otp: otp,
          password: newPassword,
        }
      );
      dispatch(userAction.forgetPasswordSuccess(data.data));
      navigate("/sign_in");
    } catch (error) {
      dispatch(userAction.forgetPasswordFail(error?.response?.data?.message));
    }
  };

export const changePassword =
  (oldPassword, newPassword, navigate) => async (dispatch) => {
    try {
      dispatch(userAction.changePasswordRequest());
      const { data } = await axios.patch(
        process.env.REACT_APP_API + "/ChangePassword",
        {
          oldPassword: oldPassword,
          newPassword: newPassword,
        }
      );
      dispatch(userAction.changePasswordSuccess(data.data));
      navigate("/profile");
    } catch (error) {
      dispatch(userAction.changePasswordFail(error?.response?.data?.message));
    }
  };

import { React, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./Favorite.css";
import { useEffect } from "react";
import WatchFavorite from "../../Components/WatchFavorite/WatchFavorite";
import { getFavorite } from "./../../Redux/homepage/HomePageAction";
import Loader from "./../../Components/Loader/Loader";
function Favorite() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [sortby, setsortby] = useState(false);
  const [sort, setsort] = useState("");
  useEffect(() => {
    dispatch(getFavorite(sort ? sort : ""));
  }, [dispatch, sort]);
  const { favorite, loading } = useSelector((state) => state.HomeReducer);



  const filters = [
    {
      label: "Sort By Price : low to high",
      value: "lowestprice",
    },
    {
      label: "Sort By Price : high to low",
      value: "highestprice",
    },

    {
      label: "Sort by availability",
      value: "availability",
    },
  ];
  const handleFilterChange = (filter) => {
    setsort(filter);
  };


  return (
    <div className="favorite-container">
      {" "}
      <div className="rout">
        <div
          className="homeroute"
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            //   navigate("/");
            //   handleClear();
          }}
        >
          HOME &nbsp;&nbsp;/
        </div>
        <div className="productroute"> &nbsp;&nbsp; &nbsp;&nbsp; Wishlist </div>
      </div>
      <div className="titleWatch">
        <p>Wishlist </p>
      </div>
      <div className="fsort">
        <div className="filter-row-cutom">
          <div className="filter-item" onClick={() => setsortby(!sortby)}>
            <div className="filter_container_title"> Sort By </div>
            <div className="filter_arrow">
              <IoIosArrowDown
                size={20}
                className={sortby ? "arrow_down" : "arrow_up"}
              />
            </div>
          </div>
          {sortby && (
            <div className="Main_filters_card212">
              {filters.map((filter) => (
                <div className="inputs_container" key={filter.value}>
                  <input
                    type="radio"
                    name="filterOption" // Same name for all radio buttons
                    id={filter.value}
                    value={filter.value}
                    className="checkbox"
                    onClick={() => handleFilterChange(filter.value)}
                  />
                  <label htmlFor={filter.value} className="label_check">
                    {filter.label}
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div
          className="container-fluid"
          style={{
            marginTop: "50px",
          }}
        >
          {favorite?.data?.data?.map((product, index) => (
            <WatchFavorite
              key={index}
              id={product?.id}
              img={product?.product?.img_url}
              name={
                product?.product?.title
                  ? product?.product?.title
                  : "No Tittle Found"
              }
              description={
                product?.product?.description
                  ? product?.product?.description
                  : "No Description Found"
              }
              price={"$" + product?.product?.price}
              linkto={product?.product?.id}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default Favorite;
